import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";
import Gauge1 from "../charts/Gauge1";
import GaugeChart from "react-gauge-chart";
import Gauge2 from "../charts/Gauge2";
import Thermometer from "react-thermometer-component";

import { saveAs } from "file-saver";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  // //TODO:
  // const [selectedMonth, setSelectedMonth] = useState(
  //   new Date().toISOString().split("-").slice(0, 2).join("-")
  // ); // Initially set to the current month

  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0],
  );

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };
  const [ison, setIson] = useState(false);

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  // console.log(resultlatest);

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setResultLatest(resultlatest);
    setMqttData(mqttdata);
    setIson(
      mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][39] === 1
          ? true
          : false
        : false,
    );
  }, 5000);
  // console.log(ison);

  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`,
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  let project_ids = JSON.parse(localStorage.getItem("project_ids"));

  const handleChange = (event) => {
    const confirmation = window.confirm(`ARE YOU SURE TO CHANGE THE DATE?`);
    //
    if (confirmation) {
      setSelectedDate(event.target.value);
    }
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    // setSelectedDate(event.target.value);
  };
  //TODO:
  // const handleMonthChange = (event) => {
  //   setSelectedMonth(event.target.value);
  // };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };

  //TODO:

  // // Update the API request data to fetch the entire month's data
  // const monthFirstDay = selectedMonth + "-01"; // Considering the format "YYYY-MM-01" for the API request
  // const monthLastDay = selectedMonth + "-31"; // Modify this based on the last day of the month

  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
    // date: `${monthFirstDay},${monthLastDay}`,
  };
  // console.log(data);

  // //TODO:  Function to handle downloading the monthly report
  // const handleDownloadMonthlyReport = () => {
  //   const selectedMonth = new Date(); // The selected month
  //   selectedMonth.setDate(1); // Set to the 1st of the month

  //   // Get the number of days in the selected month
  //   const daysInMonth = new Date(
  //     selectedMonth.getFullYear(),
  //     selectedMonth.getMonth() + 1,
  //     0
  //   ).getDate();

  //   const promises = [];
  //   for (let i = 0; i < daysInMonth; i++) {
  //     const currentDate = new Date(selectedMonth);
  //     currentDate.setDate(i + 1); // Set to each day of the month

  //     // Adjust the API call to fetch daily data
  //     // Modify this part to fit your API structure
  //     const promise = axios.get("YOUR_API_ENDPOINT", {
  //       params: {
  //         date: currentDate.toISOString(), // Set the date for each day
  //         // Add any other necessary parameters for your API request
  //       },
  //       headers: {
  //         // Include your headers here
  //       },
  //     });
  //     promises.push(promise);
  //   }

  //   Promise.all(promises)
  //     .then((responses) => {
  //       const monthlyData = responses.map((response) => response.data);
  //       const monthlyCSV = prepareMonthlyDataForCSV(monthlyData);
  //       const blob = new Blob([monthlyCSV], {
  //         type: "text/csv;charset=utf-8;",
  //       });
  //       const fileName = `MonthlyReport_${selectedMonth.toLocaleString(
  //         "default",
  //         { month: "long" }
  //       )}.csv`;

  //       saveAs(blob, fileName);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching daily data:", error);
  //       // Handle any errors with fetching the data
  //     });
  // };
  // //TODO:
  // const prepareMonthlyDataForCSV = () => {
  //   const monthlyData = timeArray.reduce((acc, date, index) => {
  //     const [year, month] = date.split("-").slice(0, 2); // Extract year and month

  //     const key = `${year}-${month}`;
  //     if (!acc[key]) {
  //       acc[key] = { date: `${year}-${month}`, totalizedflow: 0, count: 0 };
  //     }

  //     acc[key].totalizedflow += totalizedflow[index];
  //     acc[key].count++;

  //     return acc;
  //   }, {});

  //   // Convert the accumulated monthly data to a CSV string
  //   const csvData = Object.values(monthlyData)
  //     .map((month) => `${month.date},${month.totalizedflow},${month.count}`)
  //     .join("\n");

  //   return `Date,TotalizedFlow,Count\n${csvData}`;
  // };

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];

  let [flowratearr, setflowratearr] = useState([]);
  let [totalizedflow, setTotalizedflow] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  // console.log(convertTo24Hour(currentTime));

  useEffect(() => {
    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers },
      )
      .then(function (response) {
        console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);
        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));  //raw data in pairs 0f number

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 200; index++) {
            array_index = index * 2;  //the arr is processed in chunk of 2 numbers
            msgArray[index] =
              Number(message[i][array_index]) * 256 +
              Number(message[i][array_index + 1]); //f0rmula

            dataArr[i] = msgArray;  //msgarray in st0red in dataarr

           // console.log(dataArr[i]);
          }
        }
        setTimeArray(timeArray);
         //console.log(timeArray);
        //console.log(dataArr);
        //TODO: from dataarr the code extracts
        for (let i = 0; i < dataArr.length; i++) {
          totalizedflow[i] = (dataArr[i][0] * 65536 + dataArr[i][1]) / 1000;
          flowratearr[i] = (dataArr[i][2] * 65536 + dataArr[i][3]) / 1000;
        }

        // {mqttdata?.[panelinfo.cardid]
        //   ? (
        //       (Number(mqttdata[panelinfo.cardid][2]) * 65536 +
        //         Number(mqttdata[panelinfo.cardid][3])) /
        //       1000
        //     ).toFixed(2)
        //   : 0}{" "}

        setTotalizedflow(totalizedflow);
        setflowratearr(flowratearr);

         //console.log("hello from axios");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);

  //console.log(totalizedflow[0]);

  let [firstNonZeroValue, setFirstnonzerovalue] = useState([]);
  for (let k = 0; k < totalizedflow.length; k++) {
    if (totalizedflow[k] !== 0) {
      firstNonZeroValue = totalizedflow[k];
      break;
    }
    // setFirstnonzerovalue(firstNonZeroValue);
  }
  //  console.log(totalizedflow);

//TODO : THIS reduces the size of the arrays (totalizedflow, flowratearr, timeArray) by sampling every 5th value:
  let prunedTotalizedflow = [];
  let prunedflowrate = [];
  let prunedTotalizedTime = [];
  let prunedFlowTime = [];

  // new optimized array
  for (let i = 0; i < timeArray.length /5 ; i++) {
    let trueIndex = 0;

    trueIndex = i * 5;

    prunedTotalizedflow.push(totalizedflow[trueIndex]);
    prunedTotalizedTime.push(timeArray[trueIndex]);

    prunedflowrate.push(flowratearr[trueIndex]);
    prunedFlowTime.push(timeArray[trueIndex]);
  }

  // Log the arrays to verify correct values
  //console.log("Pruned Totalized Flow: ", prunedTotalizedflow);
//console.log("Pruned Time: ", prunedTotalizedTime);


  //TODO: This section computes hourly differences in totalized flow:

  let hourlyTotalized = [];
  let hourlyTotalizedTime = [];

  for (let i = 0; i < totalizedflow.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, totalizedflow.length - 1);
    hourlyTotalized.push(
      (totalizedflow[end] - totalizedflow[start]).toFixed(2),
    );
    hourlyTotalizedTime.push(timeArray[end]);
  }

  // TODO: this block calculates flow differences over 5-minute intervals:

  let totalized5min = [];
  let totalized5minTime = [];
  for (let i = 0; i < totalizedflow.length - 1; i += 5) {
    const start = Math.max(0, i - 5);
    const end = Math.min(i + 5, totalizedflow.length - 1);
    totalized5min.push((totalizedflow[end] - totalizedflow[start]).toFixed(2));
    totalized5minTime.push(timeArray[end]);
  }
   //console.log(totalized5min);
   //console.log(totalized5minTime);

  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  let totalFlow = mqttdata?.[panelinfo.cardid]
    ? (
        (Number(mqttdata[panelinfo.cardid][0]) * 65536 +
          Number(mqttdata[panelinfo.cardid][1])) /
        100
      ).toFixed(2)
    : 0;

  let totalized_flow = mqttdata?.[panelinfo.cardid]
    ? (
        (Number(mqttdata[panelinfo.cardid][0]) * 65536 +
          Number(mqttdata[panelinfo.cardid][1])) /
        1000
      ).toFixed(3) : 0;

      // console.log(totalized_flow)

      let total_Flow = Number(Math.abs(totalized_flow-firstNonZeroValue)).toFixed(2);

  return (
    <div className="flex h-screen bg-gradient-to-br from-black via-green-700 to-[#139c13] overflow-hidden">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 flex flex-wrap items-center justify-end gap-2 p-1 bg-black ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label
              htmlFor="datepicker"
              className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="p-1 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>

          {/* // Display the Month picker */}
          {/* <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="monthPicker" className="sr-only">
              Select Month
            </label>
            <input
              type="month"
              id="monthPicker"
              value={selectedMonth}
              onChange={handleMonthChange}
              className="p-1 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div> */}

          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            <WelcomeBanner />
            <div>
              <h3 className="m-2 font-mono text-sm font-semibold text-center text-white">
                ( {name_list[panelinfo.cardid]} )
              </h3>
            </div>

            {/* statuses cards */}
            {/* <div className="flex flex-col flex-wrap p-4 mb-2 font-sans bg-white border border-gray-300 border-opacity-50 rounded-lg shadow-md bg-opacity-40 backdrop-filter backdrop-blur-lg shadow-cyan-500">
              <div className="flex flex-wrap "></div>
            </div> */}
            {/* status ends here */}

            {/* //value Cards  */}
            <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap  font-sans shadow-[#00FF00]">
              {/* voltage & phase voltage divs - 8 */}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-3 m-3 text-white transition rounded-lg bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl f fle btn hover:bg-indigo duration-0 hover:duration-150">
                  <div className="m-2 mb-4 text-lg font-bold text-center uppercase">
                    TOTALIZED FLOW
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (
                            (Number(mqttdata[panelinfo.cardid][0]) * 65536 +
                              Number(mqttdata[panelinfo.cardid][1])) /
                            1000
                          ).toFixed(3)
                        : 0}{" "}
                      m <sup>3</sup>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-3 m-3 text-white transition rounded-lg bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl f fle btn hover:bg-indigo duration-0 hover:duration-150">
                  <div className="m-2 mb-4 text-lg font-bold text-center uppercase">
                    FLOW RATE
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (
                            (Number(mqttdata[panelinfo.cardid][2]) * 65536 +
                              Number(mqttdata[panelinfo.cardid][3])) /
                            1000
                          ).toFixed(2)
                        : 0}{" "}
                      m <sup>3</sup>/hr
                    </span>
                  </div>
                </div>
                
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-3 m-3 text-white transition rounded-lg bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl f fle btn hover:bg-indigo duration-0 hover:duration-150">
                  <div className="m-2 mb-4 text-lg font-bold text-center uppercase">
                    TODAYS TOTAL FLOW
                  </div> */}
                  
                  {/* <div>
                    <span className="text-xl font-bold">
                      {totalFlow >= firstNonZeroValue
                        ? (
                            (mqttdata?.[panelinfo.cardid]
                              ? (
                                  (Number(mqttdata[panelinfo.cardid][0]) *
                                    65536 +
                                    Number(mqttdata[panelinfo.cardid][1])) /
                                  1000
                                ).toFixed(2)
                              : 0) - firstNonZeroValue
                          ).toFixed(3)
                        : "INVALID DATA"}{" "}
                      m <sup>3</sup>
                    </span>
                  </div> */}
                  
                 {/* </div> */}
                 
                  {/* <div>
                    <span className="text-xl font-bold">
                      {total_Flow}
                      m <sup>3</sup>
                    </span>
                  </div>
                </div> */}

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-3 m-3 text-white transition rounded-lg bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl f fle btn hover:bg-indigo duration-0 hover:duration-150">
                  <div className="m-2 mb-4 text-lg font-bold text-center uppercase">
                    Hot Air Oven
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][150] / 10
                        : 0}
                      ° C
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            {/* value ends here */}

            <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg  ">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  FLOW RATE TREND
                </div>
                <Linechart
                  dataArr={prunedflowrate}
                  timeArr={prunedFlowTime}
                  divider={1}
                  tag="Flow Rate"
                  colour="#00FF00"
                  min={0}
                  max={10}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                />
              </div>
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2  rounded-lg shadow-lg ">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  TOTALIZED FLOW TREND
                </div>
                <Linechart
                  dataArr={prunedTotalizedflow}
                  timeArr={prunedTotalizedTime}
                  divider={1}
                  tag="Totalized Flow"
                  colour="#00FF00"
                  min={0}
                  max={10}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                />
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  TOTALIZED FLOW CONSUMED PER HOUR
                </div>

                <ProgressChart
                  dataArr={hourlyTotalized}
                  timeArr={hourlyTotalizedTime}
                  colour={"#00FF00"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Totalized Flow Per Hour"}
                />
              </div>
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  TOTALIZED FLOW CONSUMED PER 5 MINS
                </div>

                <ProgressChart
                  dataArr={totalized5min}
                  timeArr={totalized5minTime}
                  colour={"#00FF00"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Totalized Flow per 5Mins"}
                />
              </div>
              {/* <div className="flex-1 p-5 my-2 bg-white border border-gray-300 border-opacity-50 rounded-lg shadow-lg bg-opacity-30 backdrop-filter backdrop-blur-lg shadow-cyan-500 ">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  Hot Air Oven Trend
                </div>
                <Linechart
                  dataArr={chamb4prunedArr}
                  timeArr={chamb4prunedTime}
                  divider={1}
                  tag="Hot Air Oven Temp"
                  colour="#38B2AC"
                  min={0}
                  max={1}
                  date={selectedDate}
                />
              </div> */}
            </div>
            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              <div className="flex-1 p-5 my-2 bg-white border border-gray-300 border-opacity-50 rounded-lg shadow-lg bg-opacity-30 backdrop-filter backdrop-blur-lg shadow-cyan-500 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Energy<sub> / Hour</sub> Trend
                </div>
                <Barchart
                  dataArr={energyPerHour}
                  timeArr={timeHour}
                  divider={1}
                  tag="Energy"
                  colour="#38B2AC"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div> */}
            {/* charts end here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
