import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js/auto";
// import { pfinal, tfinal } from "./Container";

Chart.register(CategoryScale);

const Linechart = ({
  dataArr,
  timeArr,
  divider,
  tag,
  colour,
  min,
  max,
  date,
  name,
}) => {
  const color = dataArr.map((value) => {
    if (value < 1) {
      return "#EF4444"; //red
    } else if (value >= 1 && value < 500) {
      return "#EF5350"; // yellow;
    } else {
      return "#60A5FA";
      // return "#1E2F97";
    }
  });

  const convertToCsv = (dataArr, timeArr) => {
    const headers = ["Time", "Data"];
    const rows = timeArr.map((t, i) => [t, dataArr[i]]);

    // Log data before generating CSV
  //console.log("Data Array Before CSV Generation: ", dataArr);

    
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");
    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", `${date}_${tag}_${name}_BSS Flow Meter.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="px-0 bg-gray-900 bg-opacity-100 border border-gray-300 border-opacity-50 rounded-lg backdrop-filter backdrop-blur-lg py-7">
      {/* <h3 className="mb-4 text-xl font-bold">Power Trend</h3> */}
      <div className="flex justify-end m-2">
        <button
          className="text-white bg-green-700 btn font-inter"
          onClick={() => convertToCsv(dataArr, timeArr)}
        >
          Download
        </button>
      </div>
      <div className="flex flex-col items-center justify-center sm:flex-row">
        <div className="w-full h-full sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-1/2 md:w-full lg:w-full xl:w-full">
          <Line
            data={{
              labels: timeArr.map((t, i) => (i % divider === 0 ? t : "")),
              //   labels: [1, 2, 3, 4, 5],

              datasets: [
                {
                  label: tag,
                  //   backgroundColor: color,
                  backgroundColor: colour,
                  // hoverBackgroundColor: color.map(
                  //   (c) => `${c}D9` // lighter version of color for hover state
                  // ),
                  borderColor: colour,
                  borderDash: [5, 1],
                  borderWidth: 1,

                  data: dataArr,
                },
              ],
            }}
            options={{
              elements: {
                line: {
                  tension: 0, // makes the line straight
                  fill: {
                    target: "origin",
                    above: "rgba(147,112,219,0.3)", // color of shadow above the line
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  time: {
                    parser: "hh:mm:ss",
                    unit: "second",
                    tooltipFormat: "MMM DD, H:mm:ss a",
                    displayFormats: {
                      second: "H:mm:ss",
                    },
                  },
                  type: "category",
                  offset: true,
                  ticks: {
                    autoSkip: false,
                    maxRotation: 60,
                    minRotation: 60,
                    font: {
                      size: 0,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: false,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0,0, 0.1)",
                  },
                },
                y: {
                  //   size: 100,
                  suggestedMin: min,
                  suggestedMax: max,
                  stepSize: 10,
                  ticks: {
                    beginAtZero: true,
                    font: {
                      size: 12,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                },
              },
              barThickness: 1,

              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    font: {
                      size: 15,
                      weight: "bold",
                    },
                  },
                },
                title: {
                  display: false,
                  text: "Power Trend",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Linechart;
